import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ColorLogo from "../images/Savage_Outdoor_Defense_Color_Logo.png"
import { Link } from "gatsby"
const ThankYou = () => {
  return (
    <Layout>
      <SEO title="Thank You! | Savage Outdoor & Defense" />
      <div class="">
        <div class="bg-gray-50 sm:py-16 sm:px-6 ">
          <div class="p-8 bg-gray-800">
            <div class="max-w-full mx-auto">
              <div class="flex justify-center items-center content-center text-center">
                <div class="text-center">
                  <h2 class="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate">
                    Thank You!
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="max-w-3xl md:max-w-4xl sm:max-w-full xs:max-w-full mx-auto xs:mx-4 sm:mx-4 md:mx-4 p-4 m-4 bg-white shadow rounded">
              <h3 class="bg-green-200 p-4 text-3xl font-bold text-green-500 text-center">
                We Appreciate You!
              </h3>
              <p class="my-3">
                Thank you for reaching out! We will get back with you as soon as
                possible. In the meantime, please feel free to read up on our
                <Link to="/faq"> FAQs section</Link>.
              </p>

              <img
                src={ColorLogo}
                class="mx-auto my-6"
                alt="Savage Outdoor & Defense"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default ThankYou
